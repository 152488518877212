<template>
  <div class="filter-users">
    <div class="filter-users__btn-block">
      <div class="filter-users__btn-left">
        <div class="filter-users__btn-left__label">{{ $t('Filter') }}</div>
        <div class="filter-users__btn-left__reset-btn" @click="resetFilter">
          {{ $t('Reset') }}
        </div>
      </div>
      <div @click="closeLeftSidebar" class="lg:tw-hidden">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter-users__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="filter-users__label" for="date">Роли</label>
        <v-select
          v-model="selectedRoles"
          :components="{OpenIndicator}"
          :options="optionsRoles"
          label="title"
          multiple
          placeholder="Выбрать"
          @input="changeRoles($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import ZemDropdownList from './ui/ZemDropdownList'
import OtherService from '@/services/other.service'
import UsersService from '@/services/users.service'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect'
import ZemIcon from '@/components/ui/ZemIcon'
import RolesService from "@/services/roles.service";

export default {
  name: 'FilterUsersForm',

  components: {
    ZemDropdownList,
    ZemMultiSelect,
    ZemIcon,
  },

  data() {
    return {
      role: {
        title: 'Выбрать',
      },
      optionsRoles: [],
      filtering: {},
      selectedRoles: [],
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
    }
  },

  mounted() {
    RolesService.getRolesList().then(r => {
      this.optionsRoles = r.data.data
    })
  },

  methods: {
    resetFilter() {
      this.role = {
        title: 'Выбрать',
      }
      this.$store.commit('users/getSearch', '')
      this.selectedRoles = []
      this.filtering = {}
      this.getFilter()
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    changeRoles(event, useFilter = true) {
      const e = event
      if (e[e.length - 1].id === 0) {
        this.selectedRoles = [e[e.length - 1]]
      } else if (e[0].id === 0) {
        this.selectedRoles = [e.find(item => item.id !== 0)]
      } else {
        this.selectedRoles = e
      }
      this.filteringRoles(this.selectedRoles, 'roles')
      if (useFilter) this.getFilter()
    },
    filteringRoles(array, field) {
      let newArr = array.map(item => item.id)
      if (newArr.includes(0) || array.length === 0) {
        delete this.filtering[field]
      } else {
        this.filtering[field] = newArr
      }
    },
    getFilter() {
      let data = ''
      let counter = 0

      for (let key in this.filtering) {
        data += key + ':' + this.filtering[key]
        counter++
        if (Object.keys(this.filtering).length !== counter) data += ';'
      }

      this.$emit('filter', {
        ...data,
        counter: counter,
      })

      this.$store.commit('users/filterMain', data)
      this.$store.commit('users/getSearchCount', counter)
      UsersService.getAllUsers()
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-users {
  display: block;
  padding: 32px 24px;
  min-width: 192px;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-top: 16px;
  }

  &__dropdown {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .filter-users {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
