<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div v-if="element" class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full tw-p-4">
      <zem-card class="tw-flex-col">
        <section class="tw-w-full tw-flex tw-justify-between tw-items-start">
          <div>
            <zem-card-title>{{ element.name }}</zem-card-title>
            <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
              <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"></span>
              ID {{ element.number }}
            </div>
            <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
              <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0EC6C6] tw-rounded-full tw-mr-1"></span>
              ID {{ element.id }}
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <template v-if="!newElement && !editElement">
              <Edit3Icon
                v-if="isShowEditButton && $can('edit', 'user-update')"
                class="text-primary tw-cursor-pointer"
                size="16"
                @click="editElement = true"
              />
              <XIcon class="text-primary tw-cursor-pointer" size="18" @click="closeRightSidebar" />
            </template>
            <template v-else-if="!createItemLoading">
              <zem-link class="mr-2" @click="editElement = false"> Отменить</zem-link>
              <zem-button @click="saveData"> Сохранить</zem-button>
            </template>

            <zem-button v-if="createItemLoading" disabled>
              <zem-preloader color="light" />
            </zem-button>
          </div>
        </section>
        <slot name="bottom"></slot>
      </zem-card>
      <ZemLink :style="{display: 'inline-block'}" class="tw-mt-3" @click="$emit('on-show-modal')">
        {{ $t('Change password') }}
      </ZemLink>

      <ZemCollapse class="tw-mt-2" is-opened-default title="Данные пользователя">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ФИО</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.name }}</template>
              <ZemInput v-else v-model="element.name" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Телефон</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.phone }}</template>
              <ZemInput v-else v-model="element.phone" mask="phone" type="phone" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Email</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.email }}</template>
              <ZemInput v-else v-model="element.email" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">{{ $t('Role') }}</div>
            <div class="zem-collapse-table__column text overflow-unset">
              <template v-if="!editElement">
                {{ element.roles ? element.roles.map(role => role.title).join(', ') : '' }}
              </template>
              <ZemMultiSelect
                v-else
                :default="'Роль'"
                :disabled="!$can('edit', 'manage-admins-access')"
                :options="$store.state.other.roles"
                :placeholder="'Роль'"
                :value="element.roles"
                class="select mt-0"
                type="object"
                @input="element.roles = $event"
              />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">{{ $t('User amoCRM') }}</div>
            <div class="zem-collapse-table__column text overflow-unset">
              <template v-if="!editElement">{{ element.amocrm_user.title }}</template>
              <ZemDropdownList
                v-else
                :options="$store.state.users.usersAmoCRM"
                :value="element.amocrm_user"
                class="select m-0"
                default="Пользователь amoCRM"
                placeholder="Выбрать"
                @input="element.amocrm_user = $event"
              />
            </div>
          </div>
        </div>
      </ZemCollapse>

      <ZemCollapse v-if="element.human" class="tw-mt-2" is-opened-default title="Данные связанного контакта">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Название</div>
            <div class="zem-collapse-table__column text">{{ element.human.data.title }}</div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ФИО</div>
            <div class="zem-collapse-table__column text">{{ element.human.data.fio }}</div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Телефон</div>
            <div class="zem-collapse-table__column text">{{ element.human.data.phone }}</div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Email</div>
            <div class="zem-collapse-table__column text">{{ element.human.data.email }}</div>
          </div>
          <div
            v-if="!element.human.data.user_id"
            class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]"
          >
            <div class="zem-collapse-table__column title">ID в amoCRM</div>
            <div class="zem-collapse-table__column text">{{ element.human.data.amocrm_contact_id }}</div>
          </div>
        </div>
      </ZemCollapse>

      <ZemCollapse v-if="organization" is-opened-default title="Организация">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Название</div>
            <div class="zem-collapse-table__column text">
              {{ organization.data.title }}
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ИНН</div>
            <div class="zem-collapse-table__column text">
              {{ organization.data.inn }}
            </div>
          </div>
        </div>
      </ZemCollapse>

      <ZemCollapse is-opened-default title="Параметры">
        <div class="zem-collapse-table rows">
          <div
            v-for="schema in element['params_schema']"
            :key="schema.key"
            class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]"
          >
            <div v-tippy="{content: schema.hit}" class="zem-collapse-table__column title">{{ schema.title }}</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement && schema.key === 'i_responsible'">
                <ZemCheckbox v-model="schema.value" disabled />
              </template>
              <template v-else-if="!editElement">{{ schema.value }}</template>
              <ZemCheckbox v-else-if="schema.key === 'i_responsible'" v-model="schema.value" />
              <ZemInput v-else v-model="schema.value" />
            </div>
          </div>
        </div>
      </ZemCollapse>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import CollapseCard from '@/components/CollapseCard.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {errorHandler, formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import {Edit3Icon, XIcon} from 'vue-feather-icons'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import UsersService from '@/services/users.service'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'

export default {
  components: {
    ZemCheckbox,
    ZemMultiSelect,
    ZemDropdownList,
    ZemInput,
    PreloaderWrapper,
    ZemCollapse,
    ZemButton,
    ZemCardTitle,
    ZemLink,
    ZemCard,
    ZemPreloader,
    CollapseCard,
    XIcon,
    Edit3Icon,
  },
  data() {
    return {
      editElement: false,
      newElement: false,
      isShowEditButton: true,
      elementData: null,
      title: '',
      id: '',
      element: null,
      object: null,
      organization: null,
      createItemLoading: false,
    }
  },
  computed: {
    idParams() {
      return this.$route.params
    },
  },
  watch: {
    idParams: {
      handler() {
        this.getUser()
      },
      deep: true,
    },
  },
  mounted() {
    const {id} = this.$route.params
    if (id) this.getUser()
  },
  methods: {
    formattedPhone,
    phoneTrim,
    getUser() {
      this.$store.commit('other/onChangeLoadingItem', true)
      const {id} = this.$route.params
      UsersService.getUser(id)
        .then(r => {
          const {human, amocrm_user_id, amoCrmUser, roles} = r.data.data

          this.element = {
            ...r.data.data,
            phone: formattedPhone(human.data.phone),
            roles: roles ? roles.data.map(el => ({title: el.display_name, id: el.id})) : [],
            amocrm_user: amocrm_user_id
              ? {value: amoCrmUser.data.id, title: amoCrmUser.data.name}
              : {
                  title: '',
                  value: '',
                },
          }

          if (r.data.data.human) {
            const {priority_organization_id, priority_organization} = r.data.data.human.data

            const organizationLogic = priority_organization_id !== ''

            if (organizationLogic) {
              this.organization = priority_organization
            } else {
              this.organization = null
            }
          }

          this.$store.commit('other/onChangeLoadingItem', false)
        })
        .catch(e => {
          errorHandler(e)
        })
    },
    saveData() {
      this.createItemLoading = true
      const {id} = this.$route.params
      const {name, phone, email, roles, params_schema, human, amocrm_user} = this.element
      const body = {
        name,
        phone: phoneTrim(phone),
        email,
        ...[amocrm_user ? {amocrm_user_id: amocrm_user.value} : {}],
      }
      if (params_schema.length) {
        body.params = {}
        params_schema.forEach(el => {
          body.params[el.key] = el.value
        })
      }
      UsersService.updateUser(id, body, false).then(() => {
        if (human.data) {
          UsersService.updateRoleForUser(
            id,
            roles.map(role => role.id)
          ).then(() => {
            this.createItemLoading = false
            this.editElement = false
            this.getUser()
          })
        } else {
          this.createItemLoading = false
          this.editElement = false
          this.getUser()
        }
      })
    },
    closeRightSidebar() {
      this.editElement = false
      this.newElement = false
      this.$router.push({name: 'peoples'})
      this.$emit('on-close')
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss" scoped></style>
